<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="config.id != null">
            <div>Редактирование<br> конфигурации id {{ config.id }}</div>
          </h1>
          <h1 v-else>
            <div>Создание конфигурации паспорта региона
              <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
              <input ref="fileInput" style="display: none" type="file" @change="loadJson">
            </div>
          </h1>

          <div v-if="config.id != null" class="creation-date">Дата
            создания<span>{{ config.createdDate | dateFormat }}</span></div>

          <v-row>
            <v-col cols="12" lg="2">
              <div class="date-range-label">Период действия</div>
              <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="config.startDate | dateFormat"
                      clearable
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="config.startDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="startDateMenu=false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>


            <v-col cols="12" lg="2">
              <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="config.endDate | dateFormat"
                      clearable
                      label=""
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="config.endDate"
                    :max="maxDate"
                    :min="minDate"
                    locale="ru-RU"
                    no-title
                    scrollable
                    @input="endDateMenu=false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

          </v-row>


          <div class="list config">
            <div class="list-item-wrap">

              <v-expansion-panels>
                <v-expansion-panel
                    v-for="(section, sectionIndex) in config.sections"
                    :key="section.id"
                >
                  <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">

                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span><h4 v-if="sectionIndex===0">Разделы паспорта</h4>
                          <q>{{ section.id }}.</q>
                          <v-text-field
                              v-model="section.name"
                              @click.stop=""></v-text-field></span>
                        <div v-if="config.sections.length>1"
                             class="delete"
                             @click="deleteSection(section.id)"
                             @click.stop="">
                          <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>


                    <div class="list-item-wrap">

                      <v-expansion-panels>
                        <v-expansion-panel v-for="(indicator, indicatorIndex) in section.indicators"
                                           :key="section.id + '.' + indicator.id" class="level2">


                          <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                            <div class="list-item">
                              <div class="list-item-name">
                                <button class="toggleSwitch whiteBg">
                                  <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                  </svg>
                                  <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                  </svg>
                                </button>
                                <span>
                                  <h4 v-if="indicatorIndex === 0">Показатели</h4>
                                  <q>{{ indicator.id }}.</q>
                                  <v-text-field
                                      v-model="indicator.name"
                                      @click.stop="">
                                  </v-text-field>
                                </span>
                                <div v-if="section.indicators.length>1"
                                     class="delete"
                                     @click="deleteIndicator(section.id, indicator.id)"
                                     @click.stop="">
                                  <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content class="level3">
                            <table class="config-table">
                              <tbody>
                              <tr>
                                <td>Тип</td>
                                <td>
                                  <v-select
                                      v-model="indicator.dataType"
                                      :items="dataTypes"
                                      item-text="name"
                                      item-value="code"
                                      label="Тип"
                                      @change="dataTypeChanged(indicator, $event)"
                                  ></v-select>
                                </td>
                                <td>
                                  <p>{{
                                      indicator.dataType != null ? getDataTypeByCode(indicator.dataType).description : ''
                                    }}</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>

                            <div
                                v-if="indicator.dataType != null && getDataTypeByCode(indicator.dataType).code === 'OPTIONAL'">
                              <div class="list-item" v-for="(option, optionIndex) in indicator.options" :key="'option'+optionIndex">
                                <div class="list-item-name">
                                  <span><v-text-field v-model="option.value"></v-text-field></span>
                                  <div v-if="indicator.options.length>1"
                                       class="delete"
                                       @click="deleteOption(indicator, option)"
                                       @click.stop="">
                                    <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                  </div>
                                </div>
                              </div>
                              <a class="configAdd" href="#" @click.prevent="addOption(indicator)">+ Значение справочника</a>
                            </div>
                            <v-checkbox
                                v-model="indicator.yearly"
                                label="Значения по годам"></v-checkbox>

                            <v-expansion-panels>
                              <v-expansion-panel
                                  v-for="(subIndicator, subIndicatorIndex) in indicator.subIndicators"
                                  :key="section.id + '.' + indicator.id + '.' + subIndicator.id"
                                  class="level2">


                                <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                                  <div class="list-item">
                                    <div class="list-item-name">
                                      <button class="toggleSwitch whiteBg">
                                        <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                        </svg>
                                        <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                        </svg>
                                      </button>
                                      <span>
                                  <h4 v-if="subIndicatorIndex === 0">Подпоказатели</h4>
                                  <q>{{ subIndicator.id }}.</q>
                                  <v-text-field
                                      v-model="subIndicator.name"
                                      @click.stop="">
                                  </v-text-field>
                                </span>
                                      <div
                                           class="delete"
                                           @click="deleteSubIndicator(section.id, indicator.id, subIndicator.id)"
                                           @click.stop="">
                                        <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                      </div>
                                    </div>
                                  </div>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content class="level3">
                                  <table class="config-table">
                                    <tbody>
                                    <tr>
                                      <td>Тип</td>
                                      <td>
                                        <v-select
                                            v-model="subIndicator.dataType"
                                            :items="dataTypes"
                                            item-text="name"
                                            item-value="code"
                                            label="Тип"
                                            @change="dataTypeChanged(subIndicator, $event)"
                                        ></v-select>
                                      </td>
                                      <td>
                                        <p>{{
                                            subIndicator.dataType != null ? getDataTypeByCode(subIndicator.dataType).description : ''
                                          }}</p>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>

                                  <div
                                      v-if="subIndicator.dataType != null && getDataTypeByCode(subIndicator.dataType).code === 'OPTIONAL'">
                                    <div class="list-item" v-for="(option, optionIndex) in subIndicator.options" :key="'option'+optionIndex">
                                      <div class="list-item-name">
                                        <span><v-text-field v-model="option.value"></v-text-field></span>
                                        <div v-if="subIndicator.options.length>1"
                                             class="delete"
                                             @click="deleteOption(subIndicator, option)"
                                             @click.stop="">
                                          <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                        </div>
                                      </div>
                                    </div>
                                    <a class="configAdd" href="#" @click.prevent="addOption(subIndicator)">+ Значение справочника</a>
                                  </div>
                                  <v-checkbox
                                      v-model="subIndicator.yearly"
                                      label="Значения по годам"></v-checkbox>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <a class="configAdd" href="#" @click.prevent="addSubIndicator(indicator)">+ Добавить подпоказатель</a>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>

                    </div>
                    <a class="configAdd" href="#" @click.prevent="addIndicator(section)">+ Добавить показатель</a>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <a class="configAdd" href="#" @click.prevent="addSection()">+ Добавить раздел</a>
            </div>
          </div>


          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/passport')">Отмена</v-btn>
            <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit">Сохранить</v-btn>
          </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "PassportConfig",
  props: {},
  components: {},
  data() {
    return {
      dataTypes: [
        {code: 'INTEGER', name: 'Целое число', description: 'Целое число, возможно использование пробелов'},
        {code: 'DECIMAL', name: 'Дробное число', description: 'Дробное число, разделитель – запятая'},
        {code: 'TEXT', name: 'Текстовая строка', description: 'Строка длиной до 4000 символов'},
        {code: 'OPTIONAL', name: 'Справочник', description: 'Выбор из списка значений'},
      ],
      config: {
        id: null,
        startDate: null,
        endDate: null,
        sections: [{
          id: 1,
          name: "",
          indicators: [{id: 1, name: "", dataType: 'INTEGER'}]
        }]
      },
      startDateMenu: false,
      endDateMenu: false,
      minDate: '2019-01-01',
      maxDate: '2030-12-31',
      errorText: null,
      submitButtonDisabled: false,
      apiLoaded: false
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    //удаляет раздел из конфига
    deleteSection(sectionId) {
      for (let i = 0; i < this.config.sections.length; i++) {
        if (this.config.sections[i].id === sectionId) {
          this.config.sections.splice(i, 1)
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteIndicator(sectionId, indicatorId) {
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          for (let i = 0; i < section.indicators.length; i++) {
            if (section.indicators[i].id === indicatorId) {
              section.indicators.splice(i, 1)
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteSubIndicator(sectionId, indicatorId, subIndicatorId) {
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          for (let indicator of section.indicators) {
            if (indicator.id === indicatorId) {
              if (indicator.subIndicators != null)
                for (let i = 0; i < indicator.subIndicators.length; i++) {
                  if (indicator.subIndicators[i].id === subIndicatorId) {
                    indicator.subIndicators.splice(i, 1)
                    if(indicator.subIndicators.length === 0)
                      delete indicator.subIndicators
                    break;
                  }
                }
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    addSection() {
      if (this.config.sections == null) {
        this.config.sections = []
      }
      this.config.sections.push({
        id: 1,
        name: "",
        indicators: [{id: 1, name: "", dataType: 'INTEGER', subIndicators: []}]
      })
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addIndicator(section) {
      section.indicators.push({id: null, name: "", dataType: 'INTEGER', subIndicators: []})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addSubIndicator(indicator) {
      if(indicator.subIndicators == null)
        indicator.subIndicators = []
      indicator.subIndicators.push({id: null, name: "", dataType: 'INTEGER'})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addOption(indicator){
      if(indicator.options == null)
        indicator.options = []
      indicator.options.push({id: this.getMaxOptionId(indicator.options), value: ''})
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    getMaxOptionId(options){
      let curMaxId = 0
      for(let o of options){
        if(+o.id > curMaxId)
          curMaxId = o
      }
      return curMaxId
    },

    deleteOption(indicator, option){
      if(indicator.options.length === 0)
        return
      for(let i = 0; i < indicator.options.length; i++){
        if(indicator.options[i].id === option.id)
          indicator.options.splice(i, 1)
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    dataTypeChanged(indicator, dataType){
      if(dataType === 'OPTIONAL'){
        indicator.options = []
      } else {
        delete indicator.options
      }
      this.$forceUpdate()
    },

    //после удаления какого-то элемента порядок номеров разделов-показателей-подпоказателей собъется. Нужно все снова расставить
    //при добавлении аналогично
    ordinateNumbers() {
      for (let i = 0; i < this.config.sections.length; i++) {
        let section = this.config.sections[i]
        section.id = i + 1
        for (let j = 0; j < section.indicators.length; j++) {
          let indicator = section.indicators[j]
          indicator.id = i === 0 ? j+4 : j+1

          if(indicator.options != null){
            for(let o = 0; o < indicator.options.length; o++)
              indicator.options[o].id = o
          }

          if (indicator.subIndicators != null)
            for (let k = 0; k < indicator.subIndicators.length; k++) {
              let subIndicator = indicator.subIndicators[k]
              subIndicator.id = k + 1

              if(subIndicator.options != null){
                for(let o = 0; o < subIndicator.options.length; o++)
                  subIndicator.options[o].id = o
              }
            }
        }
      }
    },

    validate(sections) {
      if (sections == null || sections.length < 1) {
        return "Должен быть хотя бы один раздел"
      }
      for (let i = 0; i < sections.length; i++) {
        let section = sections[i]
        if (section.id !== i + 1) {
          return "Идентификаторы должны быть кратны 1 и следовать по порядку"
        }
        if (section.name == null || section.name.length === 0) {
          return "Должны быть указаны названия всех элементов"
        }
        for (let j = 0; j < section.indicators.length; j++) {
          let indicator = section.indicators[j]

          if (indicator.name == null || indicator.name.length === 0) {
            return "Должны быть указаны названия всех элементов"
          }
          if (indicator.subIndicators != null)
            for (let k = 0; k < indicator.subIndicators; k++) {
              let subIndicator = indicator.subIndicators[k]
              if (subIndicator.id !== k + 1) {
                return "Идентификаторы должны быть кратны 1 и следовать по порядку"
              }
              if (subIndicator.name == null || subIndicator.name.length === 0) {
                return "Должны быть указаны названия всех элементов"
              }
            }
        }
      }
      return true
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = function () {
        let sections

        try {
          sections = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        let validstat = reader.componentThis.validate(sections)
        if (validstat === true) {
          reader.componentThis.config.sections = sections
        } else {
          reader.componentThis.errorText = validstat
        }
      };

      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    async submit() {
      let valstat = this.validate(this.config.sections)
      if (valstat === true) {
        this.config.sectionsJson = JSON.stringify(this.config.sections)
        this.submitButtonDisabled = true
        let req = await this.$postApi("/passport/saveConfig", this.config)
        if (req.ok) {
          this.$router.push("/config/passport")
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          this.submitButtonDisabled = false
        }
      } else {
        this.errorText = valstat
      }
    },

    getDataTypeByCode(code) {
      for (let type of this.dataTypes) {
        if (type.code === code)
          return type
      }
    }
  },
  async beforeMount() {
    if (this.$route.params.id != null) {
      //режим редактирования
      let req = await this.$getApi("/passport/getConfig", {id: this.$route.params.id})
      if (req.ok) {
        this.config = req.payload
        this.config.sections = JSON.parse(this.config.sectionsJson)
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    } else {
      this.apiLoaded = true
    }
  }
};
</script>
